<template>
  <div class="row">
    <div class="col">
      <Toast ref="mensajeToast"></Toast>
      <Alerts ref="alert"></Alerts>
      <ModalPDF ref="visorPDF"></ModalPDF>
      <Confirm ref="confirm"/>
      <verify ref="modalVerify" @showToast="showToast" @mostrarDocumento="mostrarDocumento">
        <template v-slot:header><span></span></template>
      </verify>
      <div class="card border-warning">
        <div class="card-header">
          <div class="row">
            <div class="col">
              <h5 class="mt-2 mb-0">Nueva Factura Manual (de Contingencia)</h5>
            </div>
            <div class="col" v-if="!mostrarGrilla">
              <button class="btn btn-warning" @click="mostrarGrilla=true">
                Seleccionar otro estudiante
              </button>
            </div>
            <div class="col"></div>
          </div>
        </div>
        <div class="card-body p-1">
          <frmSeleccionarEstudiante ref="frmSeleccionarEstudiante" v-if="mostrarGrilla"
                                    @mostrarDatosAlumno="mostrarDatosAlumno"></frmSeleccionarEstudiante>
          <template v-if="!mostrarGrilla">
            <frmEstudianteMultas ref="frmestudiantemultas"
                                 :multa="multa"
                                 :facultades="facultades" :carreras="carreras"
                                 :nacionalidades="nacionalidades" :inscripcion="inscripcion"
                                 :cuota0="cuota0" :cuota1="cuota1" :cuota2="cuota2"
                                 :cuota3="cuota3" :cuota4="cuota4" :cuota5="cuota5"
                    :contrato="contrato">
            </frmEstudianteMultas>
            <hr class="border-primary border-1"/>
            <form ref="formCreate" novalidate>
              <div class="row mb-1">
                <div class="col-2">
                  <label class="form-label" for="numeroFactura"><strong>Número de Factura</strong></label>
                  <input type="number" :min="cafcmin" :max="cafcmax" v-model="factura.numeroFactura"
                         id="numeroFactura" class="form-control" required="required"/>
                </div>
                <div class="col-7">
                  <label class="form-label" for="eventoSignificativo"><strong>Evento Significativo</strong></label>
                  <select class="form-control pl-1 pr-1" id="eventoSignificativo"
                          v-model="factura.eventosignificativo_id" required="required">
                    <template v-for="option in eventosSignificativos">
                      <option :value="option.id">
                        {{ option.descripcion }} [{{ option.rango }}]
                      </option>
                    </template>
                  </select>
                  <div id="eventoSignificativoFeedback" class="invalid-tooltip">
                    Debe seleccionar un evento significativo.
                  </div>
                </div>
                <div class="col-3">
                  <label class="form-label" for="cafc"><strong>CAFC</strong></label>
                  <select class="form-control pl-1 pr-1" id="cafc"  required="required"
                          v-model="factura.cafc" @change="onChange($event, $event.target.selectedIndex)">
                    <option value="" selected>:: SIN CAFC ::</option>
                    <template v-for="option in cafcs">
                      <option :value="option.cafc">
                        {{ option.cafc }}
                      </option>
                    </template>
                  </select>
                  <div id="cafcFeedback" class="invalid-tooltip">
                    Debe seleccionar un código CAFC.
                  </div>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-3">
                  <label class="form-label" for="factura_nit"><strong>Nit Cliente</strong></label>
                  <input id="factura_nit" v-model="factura.factura_nit" class="form-control"
                         type="text" @change="buscarnit()" required="required"/>
                  <div class="invalid-tooltip">
                    El NIT es requerido
                  </div>
                </div>
                <div class="col-5">
                  <label class="form-label" for="factura_nombre"><strong>Nombre cliente</strong></label>
                  <input id="factura_nombre" v-model="factura.factura_nombre"
                         class="form-control" type="text" required="required"/>
                  <div class="invalid-tooltip">
                    El nombre del cliente es requerido
                  </div>
                </div>
                <div class="col-4">
                  <label class="form-label" for="fechapago">Fecha y hora</label>
                  <input id="fechapago" class="form-control" type="datetime-local" v-model="factura.factura_fecha"
                         :min="fecha_min" :max="fecha_max" required="required">
                  <div class="invalid-tooltip">
                    La fecha debe estar dentro del rango de fechas del evento significativo.
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  <label class="form-label" for="gestion"><strong>Gestión</strong></label>
                  <select class="form-control pl-1 pr-1" id="gestion"
                          v-model="factura.gestion" required="required" @change="actualizarmultas">
                    <option value="" selected>:: SELECCIONAR ::</option>
                    <template v-for="(option,key) in gestiones">
                      <optgroup :label="key">
                        <option v-for="suboption in option" :value="suboption.value" :class="tieneColor(suboption)">
                          {{ suboption.label }}
                        </option>
                      </optgroup>
                    </template>
                  </select>
                </div>
                <div class="col-3">
                  <CSelect
                      :options="moneda"
                      :plain="true"
                      :value.sync="factura.moneda_id"
                      label="Moneda:"
                      readonly="readonly"
                      class=" pb-0 mb-1"
                  >
                  </CSelect>
                </div>
                <div class="col-2">
                  <label class="form-label" for="tipocambio">Tipo de cambio:</label>
                  <input id="tipocambio" v-model="factura.tipo_cambio" class="form-control" readonly type="text">
                </div>
                <div class="col-4">
                  <CSelect
                      :options="tipoCobro"
                      :plain="true"
                      :value.sync="factura.tipo_cobro_id"
                      label="Tipo de Cobro"
                      required="required"
                      class=" pb-0 mb-1"
                      @change="actualizarpagos"
                  >
                  </CSelect>
                </div>
                <div class="col-2" v-if="factura.tipo_cobro_id!==tipoEfectivo">
                  <label class="form-label" for="tipocambio">Deposito:</label>
                  <input id="deposito" v-model="factura.deposito" class="form-control"
                         type="text" @change="actualizarDepositos">
                </div>
              </div>
              <hr class=" mt-1 mb-1"/>
              <div class="row pb-0 mb-0">
                <div class="col-6">
                  <div class="row">
                    <div class="col-1 text-center">#</div>
                    <div class="col-11 pl-0 pr-1 text-center">
                      <strong>A cobrar</strong>
                    </div>
                  </div>
                </div>
                <div class="col-2 text-center">
                  <strong>Monto</strong>
                  <font-awesome-icon icon="fa-solid fa-circle-question"
                                     v-c-tooltip="'Si los montos de las <u>cuotas</u> (<u>1,2,3,4,5</u>) no son correctos debe actualizar el <b>contrato</b> del estudiante'"/>
                </div>
                <div class="col-1 pl-0 pr-0 text-center d-none">
                  T. Cobro
                </div>
                <div class="col-2 pl-0 pr-0 text-center d-none">
                  Depósito
                </div>
                <div class="col-3 text-center">
                  Observaciones
                </div>
                <div class="col-1 pl-0">
                </div>
              </div>
              <div class="row pb-0 mb-0">
                <div class="col-6 ">
                  <div class="row">
                    <div class="col-1 text-center">1</div>
                    <div class="col-11 pl-0 pr-1">
                      <v-select :options="conceptoPago" :reduce="pago => pago.value" label="label"
                                v-model="factura.concepto_id_1" @input="actualizaMonto(1)"/>
                    </div>
                  </div>
                </div>

                <div class="col-2 pl-0 pr-1">
                  <input id="monto_1" v-model="factura.monto_1" autocomplete="false"
                         class="" step="0.1" type="number"
                         v-bind:class="[factura.monto_1 > 0 ? 'form-control is-valid' : 'form-control is-invalid']">
                </div>
                <div class="col-1 pl-0 pr-1 d-none">
                  <CSelect
                      :options="tipoCobro"
                      :plain="true"
                      :value.sync="factura.tipo_cobro_id_1"
                      label=""
                      required="required"
                      class=" pb-0 mb-1"
                  >
                  </CSelect>
                </div>
                <div class="col-2 pl-0 pr-1 d-none">
                  <template>
                    <input id="deposito_1" v-model="factura.deposito_1" class="form-control"
                           type="text" v-if="factura.tipo_cobro_id_1 !== tipoEfectivo">
                  </template>
                </div>
                <div class="col-3 pl-0 pr-1">
                  <input id="observacion_1" v-model="factura.observacion_1"
                         class="form-control" maxlength="350"
                         type="text">
                </div>
                <div class="col-1 pl-0">
                  <button :class="factura.monto_1 < 1?'btn btn-outline-secondary':'btn btn-outline-danger'"
                          :disabled="factura.monto_1 < 1" @click="limpiar(1)"
                  >Limpiar
                  </button>
                </div>
              </div>
              <div class="row pb-0 mb-0">
                <div class="col-6">
                  <div class="row">
                    <div class="col-1 text-center">2</div>
                    <div class="col-11 pl-0 pr-1">
                  <v-select :options="conceptoPago" :reduce="pago => pago.value" label="label"
                            v-model="factura.concepto_id_2" @input="actualizaMonto(2)"/>
                    </div>
                  </div>
                </div>
                <div class="col-2 pl-0 pr-1">
                  <input v-model="factura.monto_2" autocomplete="false"
                         class="" step="0.1" type="number"
                         v-bind:class="[factura.monto_2 > 0 ? 'form-control is-valid' : 'form-control is-invalid']">
                </div>
                <div class="col-1 pl-0 pr-1 d-none">
                  <CSelect
                      :options="tipoCobro"
                      :plain="true"
                      :value.sync="factura.tipo_cobro_id_2"
                      label=""
                      required="required"
                      class=" pb-0 mb-1"
                  >
                  </CSelect>
                </div>
                <div class="col-2 pl-0 pr-1 d-none">
                  <template>
                    <input id="deposito_2" v-model="factura.deposito_2" class="form-control"
                           type="text" v-if="factura.tipo_cobro_id_2 !== tipoEfectivo">
                  </template>
                </div>
                <div class="col-3 pl-0 pr-1">
                  <input v-model="factura.observacion_2" class="form-control" maxlength="350" type="text">
                </div>
                <div class="col-1 pl-0 pr-1">
                  <button :class="factura.monto_2 < 1?'btn btn-outline-secondary':'btn btn-outline-danger'"
                          :disabled="factura.monto_2 < 1" @click="limpiar(2)"
                  >Limpiar
                  </button>
                </div>
              </div>
              <div class="row pb-0 mb-0">
                <div class="col-6">
                  <div class="row">
                    <div class="col-1 text-center">3</div>
                    <div class="col-11 pl-0 pr-1">
                  <v-select :options="conceptoPago" :reduce="pago => pago.value" label="label"
                            v-model="factura.concepto_id_3" @input="actualizaMonto(3)"/>
                    </div>
                  </div>
                </div>
                <div class="col-2 pl-0 pr-1">
                  <input v-model="factura.monto_3" autocomplete="false"
                         class="" step="0.1" type="number"
                         v-bind:class="[factura.monto_3 > 0 ? 'form-control is-valid' : 'form-control is-invalid']">
                </div>
                <div class="col-1 pl-0 pr-1 d-none">
                  <CSelect
                      :options="tipoCobro"
                      :plain="true"
                      :value.sync="factura.tipo_cobro_id_3"
                      label=""
                      required="required"
                      class=" pb-0 mb-1"
                  >
                  </CSelect>
                </div>
                <div class="col-2 pl-0 pr-1 d-none">
                  <template>
                    <input id="deposito_3" v-model="factura.deposito_3" class="form-control"
                           type="text" v-if="factura.tipo_cobro_id_3 !== tipoEfectivo">
                  </template>
                </div>
                <div class="col-3 pl-0 pr-1">
                  <input v-model="factura.observacion_3" class="form-control" maxlength="350" type="text">
                </div>
                <div class="col-1 pl-0 pr-1">
                  <button :class="factura.monto_3 < 1?'btn btn-outline-secondary':'btn btn-outline-danger'"
                          :disabled="factura.monto_3 < 1" @click="limpiar(3)"
                  >Limpiar
                  </button>
                </div>
              </div>
              <div class="row pb-0 mb-0">
                <div class="col-6">
                  <div class="row">
                    <div class="col-1 text-center">4</div>
                    <div class="col-11 pl-0 pr-1">
                  <v-select :options="conceptoPago" :reduce="pago => pago.value" label="label"
                            v-model="factura.concepto_id_4" @input="actualizaMonto(4)"/>
                    </div>
                  </div>
                </div>
                <div class="col-2 pl-0 pr-1">
                  <input v-model="factura.monto_4" autocomplete="false"
                         class="" step="0.1" type="number"
                         v-bind:class="[factura.monto_4 > 0 ? 'form-control is-valid' : 'form-control is-invalid']">
                </div>
                <div class="col-1 pl-0 pr-1 d-none">
                  <CSelect
                      :options="tipoCobro"
                      :plain="true"
                      :value.sync="factura.tipo_cobro_id_4"
                      label=""
                      required="required"
                      class=" pb-0 mb-1"
                  >
                  </CSelect>
                </div>
                <div class="col-2 pl-0 pr-1 d-none">
                  <template>
                    <input id="deposito_4" v-model="factura.deposito_4" class="form-control"
                           type="text" v-if="factura.tipo_cobro_id_4 !== tipoEfectivo">
                  </template>
                </div>
                <div class="col-3 pl-0 pr-1">
                  <input v-model="factura.observacion_4" class="form-control" maxlength="350" type="text">
                </div>
                <div class="col-1 pl-0 pr-1">
                  <button :class="factura.monto_4 < 1?'btn btn-outline-secondary':'btn btn-outline-danger'"
                          :disabled="factura.monto_4 < 1" @click="limpiar(4)"
                  >Limpiar
                  </button>
                </div>
              </div>
              <div class="row pb-0 mb-0">
                <div class="col-6">
                  <div class="row">
                    <div class="col-1 text-center">5</div>
                    <div class="col-11 pl-0 pr-1">
                  <v-select :options="conceptoPago" :reduce="pago => pago.value" label="label"
                            v-model="factura.concepto_id_5" @input="actualizaMonto(5)"/>
                    </div>
                  </div>
                </div>
                <div class="col-2 pl-0 pr-1">
                  <input v-model="factura.monto_5" autocomplete="false"
                         class="" step="0.1" type="number"
                         v-bind:class="[factura.monto_5 > 0 ? 'form-control is-valid' : 'form-control is-invalid']">
                </div>
                <div class="col-1 pl-0 pr-1 d-none">
                  <CSelect
                      :options="tipoCobro"
                      :plain="true"
                      :value.sync="factura.tipo_cobro_id_5"
                      label=""
                      required="required"
                      class=" pb-0 mb-1"
                  >
                  </CSelect>
                </div>
                <div class="col-2 pl-0 pr-1 d-none">
                  <template>
                    <input id="deposito_5" v-model="factura.deposito_5" class="form-control"
                           type="text" v-if="factura.tipo_cobro_id_5 !== tipoEfectivo">
                  </template>
                </div>
                <div class="col-3 pl-0 pr-1">
                  <input v-model="factura.observacion_5" class="form-control" maxlength="350" type="text">
                </div>
                <div class="col-1 pl-0 pr-1">
                  <button :class="factura.monto_5 < 1?'btn btn-outline-secondary':'btn btn-outline-danger'"
                          :disabled="factura.monto_5 < 1" @click="limpiar(5)"
                  >Limpiar
                  </button>
                </div>
              </div>
              <div class="row pb-0 mb-0">
                <div class="col-6">
                  <div class="row">
                    <div class="col-1 text-center">6</div>
                    <div class="col-11 pl-0 pr-1">
                  <v-select :options="conceptoPago" :reduce="pago => pago.value" label="label"
                            v-model="factura.concepto_id_6" @input="actualizaMonto(6)"/>
                    </div>
                  </div>
                </div>
                <div class="col-2 pl-0 pr-1">
                  <input v-model="factura.monto_6" autocomplete="false"
                         class="" step="0.1" type="number"
                         v-bind:class="[factura.monto_6 > 0 ? 'form-control is-valid' : 'form-control is-invalid']">
                </div>
                <div class="col-1 pl-0 pr-1 d-none">
                  <CSelect
                      :options="tipoCobro"
                      :plain="true"
                      :value.sync="factura.tipo_cobro_id_6"
                      label=""
                      required="required"
                      class=" pb-0 mb-1"
                  >
                  </CSelect>
                </div>
                <div class="col-2 pl-0 pr-1 d-none">
                  <template>
                    <input id="deposito_6" v-model="factura.deposito_6" class="form-control"
                           type="text" v-if="factura.tipo_cobro_id_6 !== tipoEfectivo">
                  </template>
                </div>
                <div class="col-3 pl-0 pr-1">
                  <input v-model="factura.observacion_6" class="form-control" maxlength="350" type="text">
                </div>
                <div class="col-1 pl-0 pr-1">
                  <button :class="factura.monto_6 < 1?'btn btn-outline-secondary':'btn btn-outline-danger'"
                          :disabled="factura.monto_6 < 1" @click="limpiar(6)"
                  >Limpiar
                  </button>
                </div>
              </div>
            </form>
          </template>
        </div>
        <div class="card-footer">
          <span :style="inscripcion.codigo_alumno>0?'':'display:none'">
          <button class="mr-2 btn btn-primary" @click="confirmarGrabar()">Generar Factura (Imprimir)</button>
          </span>
          <button type="button" class="btn btn-outline-danger" @click="goBack">Regresar a la pantalla anterior</button>
        </div>
      </div>
    </div>

    <CModal
          :show.sync="modalCliente"
          :no-close-on-backdrop="true"
          :centered="true"
          :closeOnBackdrop="false"
          title="Crear cliente"
          size="lg"
          color="dark"
      >
      <template #header>
        Registrar cliente
      </template>
      <form ref="frmNuevoCliente" novalidate @submit.prevent="GrabarCliente">
        <div class="mb-3 row">
          <label class="col-sm-4 col-form-label" for="codigoTipoDocumentoIdentidad">Tipo Documento
            Identidad</label>
          <div class="col-sm-8">
            <select id="codigoTipoDocumentoIdentidad"
                    v-model="cliente.codigoTipoDocumentoIdentidad" class="form-control form-select"
                    required="required" @change="actualizaMonto(6)">
              <option v-for="option in TipoDocumentoIdentidad" :value="option.value">
                {{ option.label }}
              </option>
            </select>
            <div class="invalid-tooltip">Dato Requerido</div>
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-4 col-form-label" for="numeroDocumento">Número Documento de Identidad</label>
          <div class="col-sm-8">
            <input id="numeroDocumento" v-model="cliente.numeroDocumento" class="form-control"
                   required="required" maxlength="350"
                   type="text">
            <div class="invalid-tooltip">Dato Requerido</div>
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-4 col-form-label" for="complemento">Complemento</label>
          <div class="col-sm-8">
            <input id="complemento" v-model="cliente.complemento" class="form-control"
                   maxlength="10" type="text">
            <div class="invalid-tooltip">Dato Requerido</div>
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-4 col-form-label" for="nombreRazonSocial">Nombre / Razon Social</label>
          <div class="col-sm-8">
            <input id="nombreRazonSocial" v-model="cliente.nombreRazonSocial"
                   class="form-control" maxlength="350"
                   required="required" type="text">
            <div class="invalid-tooltip">Dato Requerido</div>
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-4 col-form-label" for="email">Email</label>
          <div class="col-sm-8">
            <input id="email" v-model="cliente.email"
                   class="form-control" maxlength="350"
                   required="required" type="email">
            <div class="invalid-tooltip">Dato Requerido</div>
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-4 col-form-label" for="telefono">Celular</label>
          <div class="col-sm-8">
            <input id="telefono" v-model="cliente.telefono"
                   class="form-control" max="99999999999" min="0"
                   required="required" type="number">
            <div class="invalid-tooltip">Dato Requerido</div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            <button class="btn btn-success" type="submit">Grabar datos del Cliente</button>
            <button aria-label="Close" class="btn btn-outline-dark ms-3" data-bs-dismiss="modal"
                    type="button" @click="cancelarGrabarCliente">Regresar a la pantalla anterior
            </button>
          </div>
        </div>
      </form>
      <template #footer-wrapper>
        <span></span>
      </template>
    </CModal>
  </div>
</template>

<script>
import axios from 'axios'

import Toast from "@/views/notifications/Toast.vue";
import Alerts from "@/views/notifications/Alerts.vue";
import ModalPDF from "@/views/notifications/ModalPDF.vue";
import Confirm from "@/views/notifications/Confirm.vue";
import verify from "@/views/facturaenlinea/ModalVerify.vue";
import frmSeleccionarEstudiante from "@/views/facturaenlinea/frmseleccionarestudiante.vue";
import frmEstudianteMultas from "@/views/facturaenlinea/frmestudiantemultas.vue";

var sourceLst;
var moment = require('moment');
var modalPdf;

export default {
  name: 'EditUser',
  components: {
    frmSeleccionarEstudiante,
    frmEstudianteMultas,
    Confirm,
    ModalPDF,
    Alerts,
    Toast,
    verify,
  },
  data: () => {
    return {
      mostrarGrilla: true,
      modalCliente:false,
      inscripcion: {
        facultad_id: 0,
        carrera_id: 0,
        codigo_alumno: '',
        gestion: '',
        apellido1: '',
        apellido2: '',
        nombres: '',
        genero_id: 0,
        fecha_nacimiento: '',
        nacionalidad_id: 0,
        documento_identificacion: '',
        traspaso_id: 0,
        estado_civil_id: 0,
        trabaja_id: 0,
        direccion: '',
        telefono: '',
        email: '',
        casilla: '',
      },
      eventosSignificativos:[],
      fecha_min:'',fecha_max:'',
      cafcs:[],cafcmin:1,cafcmax:10000000,
      facultades: [], carreras: {},
      generos: [], estados_civiles: [],
      nacionalidades: [], si_nos: [],
      maxdate: moment(new Date()).add(-12 * 10, 'M').format("YYYY-MM-DD"),
      factura_fecha: moment(new Date()).format("YYYY-MM-DD"),
      factura_hora: moment(new Date()).format("HH:mm"),
      observacion: '', tipoEfectivo: 0,

      monto_concepto:'', multa_observacion:'', multa_monto:'', multa_concepto_id:'',

      multa: 2,
      contrato: false,
      cuota0: {fecha_pago: '', diasmulta: '', multa: '', id: 0, total: 0},
      cuota1: {fecha_pago: '', diasmulta: '', multa: '', id: 0, total: 0},
      cuota2: {fecha_pago: '', diasmulta: '', multa: '', id: 0, total: 0},
      cuota3: {fecha_pago: '', diasmulta: '', multa: '', id: 0, total: 0},
      cuota4: {fecha_pago: '', diasmulta: '', multa: '', id: 0, total: 0},
      cuota5: {fecha_pago: '', diasmulta: '', multa: '', id: 0, total: 0},

      factura: {
        numeroFactura:'',
        cafc:'',
        factura_fecha:'',
        factura_hora:'',
        eventosignificativo_id:0,
        factura_nit: '',
        factura_nombre: '',
        carrera_id: 0,
        codigo_alumno: '',
        gestion: '',
        moneda_id: 0,
        tipo_cambio: 0,
        semestre: '',
        tipo_cobro_id: 0,
        sede_id: 0,
        deposito: '',
        concepto_id_1: 0, monto_1: '', observacion_1: '', tipo_cobro_id_1: 0, deposito_1: '',
        concepto_id_2: 0, monto_2: '', observacion_2: '', tipo_cobro_id_2: 0, deposito_2: '',
        concepto_id_3: 0, monto_3: '', observacion_3: '', tipo_cobro_id_3: 0, deposito_3: '',
        concepto_id_4: 0, monto_4: '', observacion_4: '', tipo_cobro_id_4: 0, deposito_4: '',
        concepto_id_5: 0, monto_5: '', observacion_5: '', tipo_cobro_id_5: 0, deposito_5: '',
        concepto_id_6: 0, monto_6: '', observacion_6: '', tipo_cobro_id_6: 0, deposito_6: '',
      },
      conceptoPago: [], moneda: [], tipoCobro: [], gestiones: [],

      sedeSeleccionada: localStorage.sedeSeleccionada,

      cliente : {
        id: 0,
        numeroDocumento: 0,
        complemento: '',
        codigoCliente: '',
        nombreRazonSocial: '',
        codigoTipoDocumentoIdentidad: 0,
        email: '',
        telefono: ''
      },
      TipoDocumentoIdentidad: [],
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    async buscarnit() {
      let self = this;
      await axios.get(this.$apiAdress + '/api/facturaenlineamanual/' + self.factura.factura_nit + '/buscarnitci?token=' + localStorage.getItem("api_token")
      ).then(function (response) {
        self.cliente = {
          id: 0,
          numeroDocumento: 0,
          complemento: '',
          codigoCliente: '',
          nombreRazonSocial: '',
          codigoTipoDocumentoIdentidad: 0,
          email: '',
          telefono: ''
        };
        try {
          if (response.data.status && response.data.nitci!==null && response.data.nitci.id > 0) {
            self.factura.factura_nombre = response.data.nitci.nombreRazonSocial;
            self.cliente = response.data.nitci
          } else {
            self.cliente.numeroDocumento = self.factura.factura_nit
            if (response.data.nitci.length > 0) {
              self.cliente = response.data.nitci
            }
            self.modalCliente=true
          }
        } catch (e) {
          self.cliente.numeroDocumento = self.factura.factura_nit
          self.modalCliente=true
        }
      }).catch(function (error) {
        modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
        self.modalCliente=true
      });
    },
    confirmarGrabar() {
      if (!modalPdf.$refs.formCreate.checkValidity()) {
        modalPdf.$refs.formCreate.classList.add('was-validated');
        modalPdf.$refs.mensajeToast.makeToast('Error', 'Algunos datos son requeridos.', 'danger');
      } else {
        modalPdf.$refs.confirm.confirm('¿Esta segur@ de grabar la factura?', 'Después de este paso no podrá realizar más cambios',
            modalPdf.store,
            function () {
            });
      }
    },
    store() {
      modalPdf.$refs.alert.show("Grabando datos");
      modalPdf.factura.codigo_alumno = modalPdf.inscripcion.codigo_alumno;
      modalPdf.factura.carrera_id = modalPdf.inscripcion.carrera_id;
      modalPdf.factura.sede_id = modalPdf.sedeSeleccionada;
      axios.post(this.$apiAdress + '/api/facturaenlineamanual/' + modalPdf.sedeSeleccionada + '/store?token=' + localStorage.getItem("api_token"),
          modalPdf.factura
      )
          .then(function (response) {
            modalPdf.inscripcion = {
              facultad_id: 0,
              carrera_id: 0,
              codigo_alumno: '',
              gestion: '',
              apellido1: '',
              apellido2: '',
              nombres: '',
              genero_id: 0,
              fecha_nacimiento: '',
              nacionalidad_id: 0,
              documento_identificacion: '',
              traspaso_id: 0,
              estado_civil_id: 0,
              trabaja_id: 0,
              direccion: '',
              telefono: '',
              email: '',
              casilla: '',
            };modalPdf.factura = {
              factura_nit: '',
              factura_nombre: '',
              carrera_id: 0,
              codigo_alumno: '',
              gestion: '',
              moneda_id: 0,
              tipo_cambio: 0,
              semestre: '',
              tipo_cobro_id: 0,
              sede_id: 0,
              deposito: '',
              concepto_id_1: 0, monto_1: '', observacion_1: '', tipo_cobro_id_1: 0, deposito_1: '',
              concepto_id_2: 0, monto_2: '', observacion_2: '', tipo_cobro_id_2: 0, deposito_2: '',
              concepto_id_3: 0, monto_3: '', observacion_3: '', tipo_cobro_id_3: 0, deposito_3: '',
              concepto_id_4: 0, monto_4: '', observacion_4: '', tipo_cobro_id_4: 0, deposito_4: '',
              concepto_id_5: 0, monto_5: '', observacion_5: '', tipo_cobro_id_5: 0, deposito_5: '',
              concepto_id_6: 0, monto_6: '', observacion_6: '', tipo_cobro_id_6: 0, deposito_6: '',
            };
            modalPdf.cliente = {
              id: 0,
              numeroDocumento: 0,
              complemento: '',
              codigoCliente: '',
              nombreRazonSocial: '',
              codigoTipoDocumentoIdentidad: 0,
              email: '',
              telefono: ''
            };
            modalPdf.$refs.alert.hide();
            modalPdf.$refs.mensajeToast.makeToast('Info', 'Factura creada satisfactoriamente.', 'success');
            modalPdf.mostrarGrilla=true
          })
          .catch(function (error) {
            modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
          });
    },
    mostrarDatosAlumno(datos){
      this.mostrarGrilla = false
      this.inscripcion = datos
      this.opcionesfactura()


      axios.get(this.$apiAdress + '/api/facturaenlineamanual/' + this.sedeSeleccionada + '/parametros?token=' + localStorage.getItem("api_token"))
          .then(function (response) {
            modalPdf.factura.cafc =''
            modalPdf.cafcs = response.data.cafcs;
            if (Object.keys(response.data.cafcs).length == 1) {
              modalPdf.factura.cafc = response.data.cafcs[0].cafc
              modalPdf.cafcmin = response.data.cafcs[0].inicio
              modalPdf.cafcmax = response.data.cafcs[0].fin
              modalPdf.factura.numeroFactura = response.data.cafcs[0].siguiente
            }
            modalPdf.factura.eventosignificativo_id=''
            modalPdf.eventosSignificativos =[{id: '', descripcion: ':: SELECCIONAR ::', fechaHoraInicioEvento:'', fechaHoraFinEvento:''}]
            if (Object.keys(response.data.eventos_significativos).length > 1) {
              modalPdf.eventosSignificativos = response.data.eventos_significativos;
            }else if (Object.keys(response.data.eventos_significativos).length == 1) {
              modalPdf.eventosSignificativos = response.data.eventos_significativos;
              modalPdf.factura.eventosignificativo_id = response.data.eventos_significativos[0].id;
              modalPdf.fecha_min = response.data.eventos_significativos[0].fechaHoraInicioEvento;
              modalPdf.fecha_max = response.data.eventos_significativos[0].fechaHoraFinEvento;
            }
          })
          .catch(function (error) {
            modalPdf.$refs.mensajeToast.makeToast('Error', error.response.data.message, 'danger');
          });
    },
    actualizarmultas() {
      axios.get(
          modalPdf.$apiAdress + '/api/facturaenlineamanual/' + this.inscripcion.codigo_alumno + '/' + modalPdf.factura.gestion + '/actualizamultas?token=' + localStorage.getItem("api_token")
      )
          .then((response) => {
            if (response.data.cuota1) {
              modalPdf.cuota0 = response.data.cuota0;
              modalPdf.cuota1 = response.data.cuota1;
              modalPdf.cuota2 = response.data.cuota2;
              modalPdf.cuota3 = response.data.cuota3;
              modalPdf.cuota4 = response.data.cuota4;
              modalPdf.cuota5 = response.data.cuota5;
            } else {
              modalPdf.cuota0 = {fecha_pago: '', diasmulta: '', multa: '', id: 0, total: 0, pagado: 0};
              modalPdf.cuota1 = {fecha_pago: '', diasmulta: '', multa: '', id: 0, total: 0, pagado: 0};
              modalPdf.cuota2 = {fecha_pago: '', diasmulta: '', multa: '', id: 0, total: 0, pagado: 0};
              modalPdf.cuota3 = {fecha_pago: '', diasmulta: '', multa: '', id: 0, total: 0, pagado: 0};
              modalPdf.cuota4 = {fecha_pago: '', diasmulta: '', multa: '', id: 0, total: 0, pagado: 0};
              modalPdf.cuota5 = {fecha_pago: '', diasmulta: '', multa: '', id: 0, total: 0, pagado: 0};
            }
            modalPdf.factura.factura_nit = response.data.factura_nit;
            modalPdf.factura.factura_nombre = response.data.factura_nombre;
            modalPdf.multa = response.data.multa;
            modalPdf.contrato = response.data.contrato;
            modalPdf.actualizaMonto(1);
          })
          .catch(function (error) {
            modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
          });
    },
    buscaMonto(concepto_id) {console.log(concepto_id)
      switch (concepto_id) {
        case this.cuota1.id:
          this.monto_concepto=this.montoValor(this.factura.concepto_id_1)
          if (this.cuota1.multa > 0) {
            this.monto_concepto=this.cuota1.monto
            this.multa_observacion = "Multa por " + this.cuota1.diasmulta + " día[s]";
            this.multa_monto=this.cuota1.multa
            this.multa_concepto_id=this.multaConceptoId('CPR-Cargo por Pagos en Retraso 1C')
          }
          break;
        case this.cuota2.id:
          this.monto_concepto = this.montoValor(this.factura.concepto_id_2)
          if (this.cuota2.multa > 0) {
            this.monto_concepto = this.cuota2.monto
            this.multa_observacion = "Multa por " + this.cuota2.diasmulta + " día[s]";
            this.multa_monto=this.cuota2.multa
            this.multa_concepto_id=this.multaConceptoId('CPR-Cargo por Pagos en Retraso 2C')
          }
          break;
        case this.cuota3.id:
          this.monto_concepto = this.montoValor(this.factura.concepto_id_3)
          if (this.cuota3.multa > 0) {
            this.monto_concepto = this.cuota3.monto
            this.multa_observacion = "Multa por " + this.cuota3.diasmulta + " día[s]";
            this.multa_monto=this.cuota3.multa
            this.multa_concepto_id=this.multaConceptoId('CPR-Cargo por Pagos en Retraso 3C')
          }
          break;
        case this.cuota4.id:
          this.monto_concepto = this.montoValor(this.factura.concepto_id_4)
          if (this.cuota4.multa > 0) {
            this.monto_concepto = this.cuota4.monto
            this.multa_observacion = "Multa por " + this.cuota4.diasmulta + " día[s]";
            this.multa_monto=this.cuota4.multa
            this.multa_concepto_id=this.multaConceptoId('CPR-Cargo por Pagos en Retraso 4C')
          }
          break;
        case this.cuota5.id:
          this.monto_concepto = this.montoValor(this.factura.concepto_id_5)
          if (this.cuota5.multa > 0) {
            this.monto_concepto = this.cuota5.monto
            this.multa_observacion = "Multa por " + this.cuota5.diasmulta + " día[s]";
            this.multa_monto=this.cuota5.multa
            this.multa_concepto_id=this.multaConceptoId('CPR-Cargo por Pagos en Retraso 5C')
          }
          break;
        case "":
          break;
        default:
          this.monto_concepto = this.montoValor(concepto_id)
          break;
      }
    },
    montoValor(busca_concepto_id){
      let tempConceptoPago = this.conceptoPago
      tempConceptoPago = tempConceptoPago.filter((item) => {
        return (item.value == busca_concepto_id)
      })
      return tempConceptoPago[0].precio_unitario;
    },
    multaConceptoId(busca_descripcion){
      let tempConceptoPagoMulta = this.conceptoPago
      tempConceptoPagoMulta = tempConceptoPagoMulta.filter((item) => {
        return (item.label == busca_descripcion)
      })
      return tempConceptoPagoMulta[0].value
    },
    actualizaMonto(item) {
      this.monto_concepto=0
      this.multa_monto=0
      this.multa_observacion=''
      this.multa_concepto_id=''
      switch (item) {
        case 1:
          this.buscaMonto(this.factura.concepto_id_1)
          this.factura.monto_1=this.monto_concepto
          if (this.multa_monto > 0) {
            this.factura.observacion_2 = this.multa_observacion;
            this.factura.monto_2=this.multa_monto
            this.factura.concepto_id_2=this.multa_concepto_id
          }
          break;
        case 2:
          this.buscaMonto(this.factura.concepto_id_2)
          this.factura.monto_2=this.monto_concepto
          if (this.multa_monto > 0) {
            this.factura.observacion_3 = this.multa_observacion;
            this.factura.monto_3=this.multa_monto
            this.factura.concepto_id_3=this.multa_concepto_id
          }
          break;
        case 3:
          this.buscaMonto(this.factura.concepto_id_3)
          this.factura.monto_3=this.monto_concepto
          if (this.multa_monto > 0) {
            this.factura.observacion_4 = this.multa_observacion;
            this.factura.monto_4=this.multa_monto
            this.factura.concepto_id_4=this.multa_concepto_id
          }
          break;
        case 4:
          this.buscaMonto(this.factura.concepto_id_4)
          this.factura.monto_4=this.monto_concepto
          if (this.multa_monto > 0) {
            this.factura.observacion_5 = this.multa_observacion;
            this.factura.monto_5=this.multa_monto
            this.factura.concepto_id_5=this.multa_concepto_id
          }
          break;
        case 5:
          this.buscaMonto(this.factura.concepto_id_5)
          this.factura.monto_5=this.monto_concepto
          if (this.multa_monto > 0) {
            this.factura.observacion_6 = this.multa_observacion;
            this.factura.monto_6=this.multa_monto
            this.factura.concepto_id_6=this.multa_concepto_id
          }
          break;
        case 6:
          this.buscaMonto(this.factura.concepto_id_6)
          this.factura.monto_6=this.monto_concepto
          if (this.multa_monto > 0) {
            this.$refs.alert.show('Este pago tiene una multa (Bs. '+this.multa_monto+') pero no se puede registrar porque no hay más epacio en la factura')
          }
          break;
      }
    },
    actualizarpagos() {
      this.factura.tipo_cobro_id_1 = this.factura.tipo_cobro_id;
      this.factura.tipo_cobro_id_2 = this.factura.tipo_cobro_id;
      this.factura.tipo_cobro_id_3 = this.factura.tipo_cobro_id;
      this.factura.tipo_cobro_id_4 = this.factura.tipo_cobro_id;
      this.factura.tipo_cobro_id_5 = this.factura.tipo_cobro_id;
      this.factura.tipo_cobro_id_6 = this.factura.tipo_cobro_id;
    },
    actualizarDepositos() {
      this.factura.deposito_1 = this.factura.deposito;
      this.factura.deposito_2 = this.factura.deposito;
      this.factura.deposito_3 = this.factura.deposito;
      this.factura.deposito_4 = this.factura.deposito;
      this.factura.deposito_5 = this.factura.deposito;
      this.factura.deposito_6 = this.factura.deposito;
    },
    reportePdf(factura_id) {
      modalPdf.$refs.alert.show('Descargando documento');
      axios.get(
          modalPdf.$apiAdress + '/api/factura/' + factura_id + '/facturapdf?token=' + localStorage.getItem("api_token"),
          {responseType: 'blob'}
      )
          .then((response) => {
            modalPdf.$refs.alert.hide();
            modalPdf.$refs.visorPDF.mostrarDocumento(response.data, 'pdf', modalPdf.goBack);
          })
          .catch(function (error) {
            modalPdf.$refs.alert.hide();
            modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
          });
    },
    limpiar(fila) {
      switch (fila) {
        case 0:
          this.factura.concepto_id_1 = "";
          this.factura.monto_1 = "";
          this.factura.observacion_1 = "";
          break;
        case 2:
          this.factura.concepto_id_2 = "";
          this.factura.monto_2 = "";
          this.factura.observacion_2 = "";
          break;
        case 3:
          this.factura.concepto_id_3 = "";
          this.factura.monto_3 = "";
          this.factura.observacion_3 = "";
          break;
        case 4:
          this.factura.concepto_id_4 = "";
          this.factura.monto_4 = "";
          this.factura.observacion_4 = "";
          break;
        case 5:
          this.factura.concepto_id_5 = "";
          this.factura.monto_5 = "";
          this.factura.observacion_5 = "";
          break;
        case 6:
          this.factura.concepto_id_6 = "";
          this.factura.monto_6 = "";
          this.factura.observacion_6 = "";
          break;
      }
    },
    opcionesfactura(){
      axios.get(this.$apiAdress + '/api/facturaenlineamanual/' + this.sedeSeleccionada + '/'+this.inscripcion.codigo_alumno+'/opcionesfactura?token=' + localStorage.getItem("api_token"))
          .then(function (response) {
            modalPdf.moneda = response.data.moneda;
            let valorPorDefecto = [{value: '', label: ':: SELECCIONAR ::'}];
            modalPdf.conceptoPago = valorPorDefecto.concat(response.data.conceptoPago);
            modalPdf.tipoCobro = response.data.tipoCobro;
            for (let tip in modalPdf.tipoCobro) {
              if (modalPdf.tipoCobro[tip].label == "EFECTIVO") {
                modalPdf.tipoEfectivo = modalPdf.tipoCobro[tip].value
                modalPdf.factura.tipo_cobro_id_1 = modalPdf.tipoCobro[tip].value
                modalPdf.factura.tipo_cobro_id_2 = modalPdf.tipoCobro[tip].value
                modalPdf.factura.tipo_cobro_id_3 = modalPdf.tipoCobro[tip].value
                modalPdf.factura.tipo_cobro_id_4 = modalPdf.tipoCobro[tip].value
                modalPdf.factura.tipo_cobro_id_5 = modalPdf.tipoCobro[tip].value
                modalPdf.factura.tipo_cobro_id_6 = modalPdf.tipoCobro[tip].value
              }
            }
            modalPdf.gestiones = response.data.gestiones;
            modalPdf.factura.moneda_id = response.data.moneda[0].value;
            modalPdf.factura.concepto_id_1 = response.data.conceptoPago[1].value;
            modalPdf.factura.tipo_cobro_id = response.data.tipoCobro[0].value;
            modalPdf.factura.gestion = response.data.mesActual;
            modalPdf.TipoDocumentoIdentidad = response.data.TipoDocumentoIdentidad;
            //console.log(modalPdf.TipoDocumentoIdentidad)
            modalPdf.actualizarmultas()
          })
          .catch(function (error) {
            console.log(error)
            modalPdf.$refs.mensajeToast.makeToast('Error', error.response.data.message, 'danger');
          });
    },
    tieneColor(suboption){
      return (suboption.hasOwnProperty('color')?'text-'+suboption.color:'')
    },
    GrabarCliente() {
      if (!this.$refs.frmNuevoCliente.checkValidity()) {
        this.$refs.frmNuevoCliente.classList.add('was-validated');
        modalPdf.$refs.mensajeToast.makeToast('', 'Algunos datos son requeridos.', 'danger');
      } else {
        let self = this
        this.modalCliente=false
        modalPdf.$refs.alert.show("Grabando datos");
        this.$refs.confirm.confirm('¿Esta segur@ de grabar el nuevo cliente?', '', function () {
          axios.post(this.$apiAdress + '/api/facturaenlineamanual/' + self.sedeSeleccionada + '/grabarcliente?token=' + localStorage.getItem("api_token"), self.cliente
          ).then(function (response) {
            modalPdf.$refs.alert.hide();
            if (response.data.cliente.id > 0) {
              self.factura.factura_nit = response.data.cliente.numeroDocumento;
              self.factura.factura_nombre = response.data.cliente.nombreRazonSocial;
              modalPdf.$refs.mensajeToast.makeToast('', 'Cliente Registrado satisfactoriamente', 'success')
            }
          }).catch(function (error) {
            this.modalCliente=true
            modalPdf.$refs.mensajeToast.makeToast('', error.response.data.message, 'danger');
          });
        }, this.cancelarGrabarCliente);
      }
    },
    cancelarGrabarCliente() {
      this.modalCliente=false
      this.factura.factura_nit = ''
      this.cliente = {}
    },
    showToast(titulo,mensaje,color){
      this.$refs.mensajeToast.makeToast(titulo,mensaje,color)
    },
    mostrarDocumento(data){
      this.$refs.visorPDF.mostrarDocumento(data)
    },
    onChange(event, selectedIndex) {
      if(selectedIndex>0) {
        this.cafcmin = this.cafcs[selectedIndex - 1].inicio
        this.cafcmax = this.cafcs[selectedIndex - 1].fin
        this.factura.numeroFactura = this.cafcs[selectedIndex - 1].siguiente
      }else{
        this.cafcmin = 1
        this.cafcmax = 1000000000
        this.factura.numeroFactura = ''
      }
    }
  },
  mounted: function () {
    modalPdf = this;
    axios.get(this.$apiAdress + '/api/inscripcion/' + this.sedeSeleccionada + '/create?token=' + localStorage.getItem("api_token"))
        .then(function (response) {
          let valorPorDefecto = [{value: '', label: ':: SELECCIONAR ::'}];
          modalPdf.facultades = valorPorDefecto.concat(response.data.facultades);
          modalPdf.carreras = response.data.carreras;
          modalPdf.generos = valorPorDefecto.concat(response.data.generos);
          modalPdf.estados_civiles = valorPorDefecto.concat(response.data.estados_civiles);
          modalPdf.nacionalidades = valorPorDefecto.concat(response.data.nacionalidades);
          modalPdf.si_nos = valorPorDefecto.concat(response.data.si_nos);
        })
        .catch(function (error) {
          modalPdf.$refs.mensajeToast.makeToast('Error', error.response.data.message, 'danger');
        });
  }
}
</script>